import { graphql } from 'gatsby';
import * as path from 'path';
import { Helmet } from 'react-helmet';
import React, { useEffect, useState } from 'react';

import { SharePageQuery } from '../types/graphql';

import Layout from './layout';

import fileIcon from '../../static/images/file-text-outline.svg';

import '../styles/components/button.scss';
import '../styles/pages/share-template.scss';

interface SharePageProps {
  data: {
    defaultHeader: SharePageQuery['defaultHeader'];
    defaultFooter: SharePageQuery['defaultFooter'];
  };
}

interface ShareTemplateData {
  title: string;
  description: string;
  fileName: string;
  shareInfo: string;
  downloadLink: string;
  downloadText: string;
}

const SharePage: React.FC<SharePageProps> = ({
  data: { defaultFooter, defaultHeader },
}) => {
  const [shareData, setShareData] = useState<ShareTemplateData>({
    title: '{{title}}',
    description: '{{description}}',
    fileName: '{{fileName}}',
    shareInfo: '{{shareInfo}}',
    downloadLink: '{{downloadLink}}',
    downloadText: '{{downloadText}}',
  });

  const loadData = async () => {
    const result = await fetch(
      `${process.env.GATSBY_CF_ENDPOINT}/circleShare/share/${path.basename(
        window.location.pathname,
      )}?json=true`,
    );

    if (result.ok) {
      setShareData(await result.json());
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Layout
      headerData={defaultHeader}
      footerData={defaultFooter}
      alternateLanguages={[]}
    >
      <section className="circle-share">
        <Helmet
          htmlAttributes={{ lang: 'en' }}
          title={shareData.title}
          meta={[
            { name: 'description', content: shareData.description },
            { name: 'og:type', content: 'website' },
            { name: 'og:title', content: shareData.title },
            { name: 'og:description', content: shareData.description },
            {
              name: 'og:image',
              content: 'https://www.dekema.com/images/share-social-card.jpg',
            },
            { name: 'twitter:card', content: 'summary_large_image' },
          ]}
        />

        <div className="file">
          <img src={fileIcon} alt="File Icon" />
          <h1>{shareData.fileName}</h1>
          <p>{shareData.shareInfo}</p>
          <a
            className="btn"
            target="_blank"
            href={shareData.downloadLink}
            rel="noopener noreferrer"
          >
            {shareData.downloadText}
          </a>
        </div>
      </section>
    </Layout>
  );
};

export default SharePage;

export const query = graphql`
  query SharePage {
    defaultHeader: markdownRemark(
      fields: { collection: { eq: "header" }, lang: { eq: "en" } }
    ) {
      ...Header
    }

    defaultFooter: markdownRemark(
      fields: { collection: { eq: "footer" }, lang: { eq: "en" } }
    ) {
      ...Footer
    }
  }
`;
