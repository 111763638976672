import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { useLocation, WindowLocation } from '@reach/router';
import classNames from 'classnames';
import { graphql, GatsbyLinkProps } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import invariant from 'tiny-invariant';

import { isQmEnabledForAll } from '../../data/feature-flags';
import linkResolver from '../../link-resolver';
import { useMediaQuery } from '../hooks/dom';
import { useTrophies } from '../hooks/trophies';
import { useInitUser, useUserData } from '../hooks/user';
import { HeaderEntry, HeaderFrontmatter, PageDataQuery } from '../types/graphql';
import { Language } from '../types/graphql-extended';
import Link from '../util/link';

import languagePriorities from '../../data/language-priorities.json';
import caret from '../../static/images/chevron-down.svg';
import logo from '../../static/images/dekema-logo.png';
import closeIcon from '../../static/images/close.svg';
import hamburgerIcon from '../../static/images/hamburger.svg';

import '../styles/components/header.scss';

export interface HeaderProps {
  alternateLanguages: readonly Language[];
  currentPage?: PageDataQuery['page'];
  header: PageDataQuery['header'];
  overrideServiceMenu?: MenuItem[];
  overrideLanguage?: string;
  variant?: string;
}

export interface MenuItem {
  isActive?: boolean;
  title: string;

  hideInDropdownMenu?: boolean;
  hideOnHome?: boolean;

  restricted?: boolean;
  iconWhite?: string;
  iconRed?: string;

  link: (() => void) | string;
  onHover?: () => void;
}

interface NestableMenuItem {
  children: HeaderEntry[];
  item: HeaderEntry;
}

interface MenuItemProps extends Partial<GatsbyLinkProps<any>> {
  active?: boolean;
  currentPage: PageDataQuery['page'];
  inactive?: boolean;
  item?: HeaderEntry | null;
  lang?: string;
  location?: WindowLocation<unknown>;
}

/** A menu item. */
const Item = React.forwardRef<typeof Link, MenuItemProps>(
  ({ active, currentPage, inactive, item, lang, location, ...rest }, ref) => {
    // Check if the path matches
    const indexOfLinkPart = item?.url?.indexOf('//');
    const pathPart =
      indexOfLinkPart && indexOfLinkPart >= 0
        ? item?.url?.slice(indexOfLinkPart + 2)
        : null;
    const pathMatches = location && pathPart && location.pathname.includes(pathPart);

    const resolvedUrl = item?.url || linkResolver({ uid: item?.rel, lang });

    return (
      <Link
        {...rest}
        key={item?.title ?? ''}
        activeClassName="active"
        className={classNames({
          active:
            !inactive &&
            (active || currentPage?.fields.uid === item?.rel || pathMatches),
          dead: !resolvedUrl,
        })}
        to={resolvedUrl || ''}
        ref={ref as any}
      >
        {item?.title}
      </Link>
    );
  },
);

/** Menu bar rendering menu items. */
const Menu: React.FC<{
  currentPage: PageDataQuery['page'];
  header: HeaderFrontmatter;
  overrideServiceMenu?: MenuItem[];
  language: string;
}> = ({ currentPage, header, overrideServiceMenu, language }) => {
  const { user } = useInitUser();

  // Build nested menu out of prismic menu entries
  const nestedItems = useMemo(() => {
    const items = header?.entries;
    if (!items) {
      return [];
    }

    const results: NestableMenuItem[] = [];

    // Group sub menu items under their parent.
    //
    // The parent <-> child relationship is determined by a flag `is_submenu` and the
    // order of the elements. See
    // https://github.com/dekema-dental/website/issues/362#issuecomment-1040370555
    // for an explanation.
    for (let i = 0; i < items.length; i++) {
      const currentTopLevel = items[i];

      if (!currentTopLevel || currentTopLevel.is_submenu) {
        throw new Error(
          'Cannot start menu building with submenu. Make sure is_submenu is false for the first menu item.',
        );
      }

      const entry: NestableMenuItem = {
        item: currentTopLevel,
        children: [],
      };

      // For every top level element, the next elements that are marked as submenus
      // belong under it.
      let j = i + 1;
      for (; j < items.length && items[j]?.is_submenu; j++) {
        entry.children.push(items[j]);
        i += 1;
      }

      results.push(entry);
    }

    return results;
  }, [header?.entries]);

  const prefixFreeLinks = useMemo(() => {
    const links = overrideServiceMenu
      ?.map(({ link }) => link)
      .filter((link): link is string => typeof link === 'string');
    return (
      links?.filter((a) => !links.some((b) => b !== a && b.startsWith(a))) ?? []
    );
  }, [overrideServiceMenu]);

  const location = useLocation();

  const onSubpage = ({ link }: MenuItem) => {
    if (typeof link !== 'string') {
      return;
    }
    const { pathname } = location;
    return (
      pathname === link ||
      (prefixFreeLinks.includes(link) && pathname.startsWith(link))
    );
  };

  return (
    <>
      {nestedItems.map(({ children, item }, i) => {
        const isServiceItem = item?.rel === 'login' && !!user;
        const isInService = location.pathname.includes('/service');

        /**
         * The media area needs to interact with the currently signed-in user. It is
         * therefore a child of the service component. However, in the menu it's not
         * a child of the service entry, because it is also available from the public
         * portion of the DEKEMA website. Therefore, that page needs special
         * treatment.
         */
        const isInMediaArea = location.pathname.includes('/media');

        const itemToRender = isServiceItem
          ? {
              ...item,
              title: 'Online',
              link: {
                url: linkResolver({
                  lang: language,
                  uid: 'service',
                }),
              },
            }
          : item;

        return (
          <div key={i} className="menu-entry">
            <Item
              active={isServiceItem && isInService}
              currentPage={currentPage}
              inactive={isServiceItem && isInMediaArea}
              item={itemToRender}
              lang={language}
              location={location}
            />

            {(children.length > 0 || isServiceItem) && (
              <nav>
                {children.map((item, i) => (
                  <Item
                    key={i}
                    currentPage={currentPage}
                    item={item}
                    lang={language}
                  />
                ))}

                {isServiceItem &&
                  overrideServiceMenu
                    ?.filter((item) => !item.hideInDropdownMenu)
                    .map((item, i) => (
                      <Link
                        key={`service-${i}`}
                        to={typeof item.link !== 'function' ? item.link : '#'}
                        onClick={(ev) => {
                          if (typeof item.link === 'function') {
                            ev.preventDefault();
                            item.link();
                          }
                        }}
                        onMouseEnter={item.onHover}
                        activeClassName="active"
                        className={classNames({
                          active: item.isActive || onSubpage(item),
                        })}
                      >
                        {item.title.replace('- ', '-')}
                      </Link>
                    ))}
              </nav>
            )}
          </div>
        );
      })}
    </>
  );
};

const Header: React.FC<HeaderProps> = ({
  alternateLanguages,
  currentPage,
  header,
  overrideLanguage,
  overrideServiceMenu,
  variant,
}) => {
  invariant(
    header?.frontmatter?.__typename === 'HeaderFrontmatter',
    `need a header, not a ${header?.frontmatter?.__typename}`,
  );

  const isOnSmallScreen = useMediaQuery('(max-width: 1024px)');
  const [mobileMenuShown, setMobileMenuShown] = useState<boolean | undefined>(
    undefined,
  );
  const [scrollY, setScrollY] = useState(0);

  const { userData } = useUserData();
  const trophies = useTrophies();

  const toggleMenu = useCallback(() => {
    setScrollY(window.scrollY);
    setMobileMenuShown((shown) => !shown);
  }, []);

  const handleBackdropClick = useCallback((ev: React.MouseEvent<HTMLElement>) => {
    if (ev.currentTarget.tagName === 'NAV') {
      setMobileMenuShown(false);
    }
  }, []);

  const sortedLanguages = useMemo(() => {
    const languages = [...alternateLanguages];
    return languages
      .sort(
        (a, b) =>
          languagePriorities.indexOf(a.lang ?? '') -
          languagePriorities.indexOf(b.lang ?? ''),
      )
      .filter((l) => l);
  }, [alternateLanguages]);

  const lang = overrideLanguage || currentPage?.fields?.lang || header.fields.lang!;

  // Prevent scrolling the body when the mobile menu is open.
  useLayoutEffect(() => {
    // Do nothing on the initial run of the hook.
    if (mobileMenuShown === undefined) {
      return;
    }

    if (mobileMenuShown) {
      document.body.style.top = `-${scrollY}px`;
      document.body.classList.add('menu-open');
    } else {
      document.body.style.top = '';
      document.body.classList.remove('menu-open');

      // Restore original scroll position
      window.scrollTo(0, scrollY);
    }
  }, [mobileMenuShown, scrollY]);

  // Append any sub-path of the current page to the link to the other languages
  const baseLanguageLink = linkResolver({ uid: currentPage?.fields.uid, lang });
  const currentPathname =
    typeof window !== 'undefined' ? window.location.pathname : '';
  const pathSuffix = currentPathname.startsWith(baseLanguageLink)
    ? currentPathname.slice(baseLanguageLink.length)
    : '';

  return (
    <header className={classNames({ open: isOnSmallScreen && mobileMenuShown })}>
      <Link
        to={linkResolver({
          uid: 'home',
          lang: header?.fields.lang,
          type: 'page',
        })}
      >
        <img src={logo} alt="Dekema Logo" className="logo" />
      </Link>
      {variant && <span className="variant">{variant}</span>}

      {(isQmEnabledForAll || userData?.hasQmAccess) && trophies?.highestTrophy && (
        <Link
          className="trophy"
          to={linkResolver({ uid: 'service/qm', lang: header?.fields.lang })}
        >
          <GatsbyImage
            imgStyle={{ objectFit: 'contain' }}
            image={trophies.imagesMap[trophies.highestTrophy]}
            alt={trophies.highestTrophy}
          />
          {`${trophies.totalJobCount} ${lang.includes('de') ? 'Punkte' : 'Points'}`}
        </Link>
      )}

      <nav onClick={handleBackdropClick}>
        <Menu
          currentPage={currentPage}
          header={header?.frontmatter}
          language={lang}
          overrideServiceMenu={overrideServiceMenu}
        />

        <div className="language-switcher">
          <span>
            {lang.split('-')[0].toUpperCase()}
            {!!alternateLanguages!.length && <img src={caret} alt="Caret down" />}
          </span>

          <nav>
            {sortedLanguages.map((l) => (
              <Link
                key={`lang-${l.uid}-${l.lang}`}
                to={linkResolver({ uid: l.uid!, suffix: pathSuffix, lang: l.lang! })}
                hrefLang={l.lang!}
                rel="alternate"
                className={classNames({ active: l.lang === lang })}
              >
                {l.lang!.split('-')[0]}
              </Link>
            ))}
          </nav>
        </div>
      </nav>

      <button
        className={classNames('show-menu', { open: mobileMenuShown })}
        onClick={toggleMenu}
      >
        <img className="show" src={hamburgerIcon} alt="Show Menu" />
        <img className="hide" src={closeIcon} alt="Hide Menu" />
      </button>
    </header>
  );
};

export default React.memo(Header);

export const query = graphql`
  fragment Header on MarkdownRemark {
    frontmatter {
      ... on HeaderFrontmatter {
        __typename
        title
        entries {
          is_submenu
          rel
          title
          url
        }
      }
    }
    fields {
      lang
    }
  }
`;
