import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import invariant from 'tiny-invariant';

import { PageDataQuery } from '../types/graphql';
import { getHref } from '../util/link';

import '../styles/components/footer.scss';

import socialIcons from '../../data/social-icons.json';

import flame from '../../static/images/dekema-logo-small.svg';
import mailIcon from '../../static/images/envelope-o.svg';
import faxIcon from '../../static/images/fax.svg';
import phoneIcon from '../../static/images/phone.svg';

interface FooterProps {
  footer: PageDataQuery['footer'];
}

const Footer: React.FC<FooterProps> = ({ footer }) => {
  invariant(
    footer?.frontmatter?.__typename === 'FooterFrontmatter',
    'need a footer',
  );

  const filteredFooterMenu = footer!.frontmatter.footer_bar_menu
    ? footer!.frontmatter.footer_bar_menu!.filter(
        (item) => item?.title && item?.link,
      )
    : [];

  return (
    <footer>
      <div className="footer-slogan">
        {footer!.frontmatter?.image?.childImageSharp?.gatsbyImageData && (
          <GatsbyImage
            className="background"
            image={footer!.frontmatter?.image?.childImageSharp?.gatsbyImageData}
            alt={footer!.frontmatter.image_alt || ''}
            imgStyle={{ objectFit: 'cover' }}
          />
        )}
        <div className="container">
          <h3>{footer!.frontmatter.slogan!}</h3>
          <img className="flame" src={flame} alt="DEKEMA Flame" />
        </div>
      </div>

      <div className="footer-main">
        <div className="container">
          <div className="wrapper">
            <div className="contact">
              <h4>{footer!.frontmatter!.contact_title!}</h4>
            </div>
            <div className="contact-info">
              <a
                href="https://goo.gl/maps/Qkt7nQQWBjq"
                rel="noopener noreferrer"
                target="_blank"
                dangerouslySetInnerHTML={{
                  __html: footer?.frontmatter?.address?.html ?? '',
                }}
              />
              <ul>
                <li>
                  <img className="contact-icon" src={phoneIcon} alt="" />
                  <a href={'tel:' + footer!.frontmatter!.phone!}>
                    {footer!.frontmatter!.phone!}
                  </a>
                </li>
                <li>
                  <img className="contact-icon" src={faxIcon} alt="" />
                  <a href={'fax:' + footer!.frontmatter!.fax!}>
                    {footer!.frontmatter!.fax!}
                  </a>
                </li>
                <li>
                  <img className="contact-icon" src={mailIcon} alt="" />
                  <a href={'mailto:' + footer!.frontmatter!.email!}>
                    {footer!.frontmatter!.email!.replace('@', '[at]')}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="social-media">
            <h4>{footer!.frontmatter!.social_media_title!}</h4>
            <ul>
              {socialIcons.map((item, i) => (
                <li key={i}>
                  <a href={item.link} target="_blank" rel="noopener noreferrer">
                    <img src={`/images/social/${item.icon}.svg`} alt={item.title} />
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <div className="footer-bar">
        <div className="container">
          <ul>
            {filteredFooterMenu.map((item, i) => (
              <li key={i}>
                <a href={getHref(item.link, footer.fields.lang)}>{item!.title}</a>
              </li>
            ))}
          </ul>
          <p>{footer!.frontmatter!.credits!}</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

export const query = graphql`
  fragment Footer on MarkdownRemark {
    frontmatter {
      ... on FooterFrontmatter {
        __typename
        address {
          html
        }
        contact_title
        credits
        email
        fax
        footer_bar_menu {
          title
          link {
            ...Link
          }
        }
        image {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        image_alt
        phone
        slogan
        social_media_title
      }
    }
    fields {
      lang
    }
  }
`;
