import { graphql, useStaticQuery } from 'gatsby';
import type { IGatsbyImageData } from 'gatsby-plugin-image';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import { getTrophiesFromJobCount } from '../../shared-types/trophies';
import { getJobCountsForUser } from '../model/trophies';
import { TrophyImagesQuery } from '../types/graphql';
import { startProgress } from '../util/progress';

import { createDataHook } from './context';

// This query is here and not in the component because it's used in multiple
// components and declaring it here let's us keep things DRY.
const trophiesQuery = graphql`
  query TrophyImages {
    allFile(filter: { sourceInstanceName: { eq: "trophies" } }) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, height: 200)
          resize {
            src
          }
        }
      }
    }
  }
`;

const trophiesHook = (userToken: string | null, uid?: string) => {
  const trophyImages: TrophyImagesQuery = useStaticQuery(trophiesQuery);
  const imagesMap = useMemo(
    () =>
      trophyImages.allFile.nodes.reduce((acc, node) => {
        acc[node.name] = node.childImageSharp?.gatsbyImageData;
        acc[node.name].publicURL = node.childImageSharp!.resize!.src!;

        // Include the front faces also w/o the `-front` suffix to simplify usage in
        // other parts of the codebase that don't care about the 3D-flip effect.
        if (node.name.includes('front')) {
          const [baseName] = node.name.split('-');
          acc[baseName] = node.childImageSharp?.gatsbyImageData;
          acc[baseName].publicURL = node.childImageSharp!.resize!.src!;
        }

        return acc;
      }, {} as { [key: string]: IGatsbyImageData & { publicURL: string } }),
    [trophyImages],
  );

  const [totalJobCount, setTotalJobCount] = useState<number | null>(null);

  const refreshTrophies = useCallback(() => {
    if (!userToken) {
      return;
    }

    const state = { canceled: false };

    (async () => {
      const p = startProgress();
      try {
        const { totalCount } = await getJobCountsForUser(uid, userToken);
        if (!state.canceled) {
          setTotalJobCount(totalCount);
        }
      } catch (err) {
        console.error('Fetching badges failed:', err);
        toast("Couldn't fetch badges", { type: 'error' });
      } finally {
        p.done();
      }
    })();

    return () => {
      state.canceled = true;
    };
  }, [uid, userToken]);

  useEffect(refreshTrophies, [refreshTrophies]);

  return useMemo(() => {
    if (totalJobCount === null) {
      return null;
    }

    return {
      ...getTrophiesFromJobCount(totalJobCount),
      imagesMap,
      refreshTrophies,
      totalJobCount,
    };
  }, [imagesMap, refreshTrophies, totalJobCount]);
};

export const {
  Context: TrophiesContext,
  useData: useTrophies,
  useInitData: useInitTrophies,
} = createDataHook(trophiesHook, null);
