import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { Slide, ToastContainer } from 'react-toastify';

import linkResolver from '../../link-resolver';
import Footer from '../components/footer';
import Header, { MenuItem } from '../components/header';
import { Loader } from '../components/loader';
import { LanguageProvider } from '../context/language';
import { useUser } from '../hooks/user';
import { PageDataQuery } from '../types/graphql';
import { Language } from '../types/graphql-extended';

import 'react-toastify/dist/ReactToastify.css';
import '../styles/slices/index.scss';
import '../styles/styles.scss';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

export interface LayoutProps {
  allowedRoles?: readonly string[] | null | undefined;
  alternateLanguages: readonly Language[];
  children?: React.ReactNode;
  currentPage?: PageDataQuery['page'];
  footerData: PageDataQuery['footer'];
  headerData: PageDataQuery['header'];
  overrideLanguage?: string;
  overrideServiceMenu?: MenuItem[];
  variant?: string;
}

const Layout: React.FC<LayoutProps> = ({
  allowedRoles,
  alternateLanguages,
  children,
  currentPage,
  footerData,
  headerData,
  overrideLanguage,
  overrideServiceMenu,
  variant,
}) => {
  const { user, authStateKnown } = useUser();
  const [loadingRights, setLoadingRights] = useState(!!allowedRoles);

  useEffect(() => {
    if (!authStateKnown || !allowedRoles) {
      return;
    }

    if (user?.role && allowedRoles.includes(user.role)) {
      console.log(user?.role, 'is in', allowedRoles);
      setLoadingRights(false);
    } else {
      sessionStorage.setItem('loginTarget', linkResolver(currentPage as any));
      navigate('/login');
    }
  }, [authStateKnown, allowedRoles, user]);

  return (
    <LanguageProvider value={overrideLanguage ?? currentPage?.fields?.lang ?? 'en'}>
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.GATSBY_RECAPTCHA_V3_KEY!}
        scriptProps={{ async: true, defer: true }}
        useEnterprise
      >
        <ToastContainer transition={Slide} />
        <Header
          alternateLanguages={alternateLanguages}
          currentPage={currentPage}
          header={headerData}
          overrideLanguage={overrideLanguage}
          overrideServiceMenu={overrideServiceMenu}
          variant={variant}
        />
        {loadingRights ? <Loader variant="bright-bg" /> : children}
        <Footer footer={footerData} />
      </GoogleReCaptchaProvider>
    </LanguageProvider>
  );
};

export default Layout;
