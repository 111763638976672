import { useCallback, useLayoutEffect, useState } from 'react';

/**
 * A hook that returns whether the current device is AR-capable.
 */
export const useIsArCapable = () => {
  const [arCapable, setArCapable] = useState(false);

  useLayoutEffect(() => {
    const a = document.createElement('a');
    setArCapable(Boolean(a.relList?.supports?.('ar')));
  }, []);

  return arCapable;
};

/**
 * Measure the dimensions of the DOM element in the given ref.
 *
 * The element will be remeasured on `resize`.
 *
 * @param ref The ref containing a DOM element to measure.
 * @returns A tuple of a DOMRect with the measured dimensions of the element
 *  and a memoized function to trigger a remeasure programmatically.
 */
export const useMeasure = (
  ref: React.RefObject<Element | undefined>,
): [DOMRect | null, () => void] => {
  const [dimensions, setDimensions] = useState<DOMRect | null>(null);

  const measure = useCallback(() => {
    const node = ref.current;

    if (!node) {
      return;
    }

    window.requestAnimationFrame(() => {
      const dimensions = node!.getBoundingClientRect();
      setDimensions(dimensions);
    });
  }, [ref]);

  useLayoutEffect(() => {
    measure();

    window.addEventListener('resize', measure);
    return () => window.removeEventListener('resize', measure);
  }, [measure]);

  return [dimensions, measure];
};

/** A hook that watches whether a media query matches. */
export const useMediaQuery = (query: string) => {
  const [matches, setMatches] = useState(false);

  useLayoutEffect(() => {
    // During prerendering, `window` is undefined
    if (typeof window === 'undefined') {
      return;
    }

    const media = window.matchMedia(query);
    setMatches(media.matches);

    const handler = (ev: MediaQueryListEvent) => setMatches(ev.matches);
    if (typeof media.addEventListener === 'function') {
      media.addEventListener('change', handler);
      return () => media.removeEventListener('change', handler);
    } else {
      media.addListener(handler);
      return () => media.removeListener(handler);
    }
  }, [query]);

  return matches;
};

export const usePrefersReducedMotion = () =>
  useMediaQuery('(prefers-reduced-motion)');
