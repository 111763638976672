export const TROPHIES = [2000, 5000, 10_000, 50_000, 100_000, 200_000];

export interface TrophyData {
  highestTrophy: string | null;
  nextTrophy: string | null;
  orderedTrophies: string[];
  percentToNext: number;
  trophies: Record<string, boolean>;
}

export const getTrophiesFromJobCount = (jobCount: number): TrophyData => {
  const trophies = TROPHIES.reduce((acc, num) => {
    acc[num] = jobCount >= num;
    return acc;
  }, {} as Record<string, boolean>);

  const orderedTrophies = TROPHIES.filter((t) => trophies[t]);
  const highestTrophy =
    orderedTrophies.length > 0 ? orderedTrophies[orderedTrophies.length - 1] : null;
  const nextTrophy =
    TROPHIES[highestTrophy ? TROPHIES.indexOf(highestTrophy) + 1 : 0];
  const percentToNext =
    nextTrophy !== null
      ? (jobCount - (highestTrophy ?? 0)) / (nextTrophy - (highestTrophy ?? 0))
      : 0;

  return {
    highestTrophy: highestTrophy !== null ? String(highestTrophy) : null,
    nextTrophy: nextTrophy ? String(nextTrophy) : null,
    orderedTrophies: orderedTrophies.map((v) => String(v)),
    percentToNext,
    trophies,
  };
};
