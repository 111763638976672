/** Whether the new QM feature is enabled for all customers or only select ones. */
export const isQmEnabledForAll = true;

/**
 * Whether to force users to insert an additional authentication code during furnace
 * registration. Without the additional code, certain features on the website are
 * disabled.
 */
export const useNewFurnaceRegistrationFlow = true;

/** Whether the CCF calibration is unlocked for all users or just admins. */
export const isCcfCalibrationEnabledForAll = true;

/** Whether the special 220 order screen is enabled for dealers or not. */
export const isOrder220Enabled = false;
